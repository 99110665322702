import React, {useEffect, useState} from "react";
import "../../../assets/styles/catalogue/catalogue.css"
import Products from "./layouts/Products";
import Product from "../../../types/Product";
import {useLoaderData, useParams} from "react-router-dom";
import {FilterUtility} from "../../../utils/FilterUtility";

import Filter from "./layouts/Filter";


import {filtProducts} from "../../../utils/filtProducts";


const Catalogue = () => {
    let products = useLoaderData() as Product[];
    const [filters, setFilters] = useState({name: "", tag: "", category: ""});
    const [productsToDisplay, setProductsToDisplay] = useState<Product[]>(products.sort((a, b) => a.name.localeCompare(b.name)));
    const {tag} = useParams();
    const [firstTimeFilterByParam, setFirstTimeFilterByParam] = useState(!!tag);

    useEffect(() => {
        if (filters.tag || filters.name) {
            setProductsToDisplay(filtProducts(products, filters))
        } else if (tag && firstTimeFilterByParam) {
            const category = FilterUtility.getCategoryByTag(tag)
            const parameterFilter = {tag: tag, category: category, name: ""}
            setFilters(parameterFilter)
            setProductsToDisplay(filtProducts(products, parameterFilter))
            setFirstTimeFilterByParam(false);
        } else {
            setProductsToDisplay(products.sort((a, b) => a.name.localeCompare(b.name)))
        }
    }, [filters.tag, filters.name]);

    if (tag) {
        const tagExist = FilterUtility.getCategoryByTag(tag)
        const tagIsANumber = !isNaN(parseInt(tag));
        if (tagIsANumber || !tagExist) {
            window.location.href = '/productos';
        }
    }


    return (
        <div className="products-layout" id={"#products-inicio"} key={"catalogue"}>
            <Filter activeFilter={filters} setActiveFilter={setFilters}/>
            <Products products={productsToDisplay}/>
        </div>
    )
}

export default Catalogue;