import Product from "../types/Product";
import {filters} from "./filters";
import filter from "../components/pages/catalogue/layouts/Filter";

export function filtProducts(products: Product[], {name, tag, category}: {
    name: string,
    tag: string,
    category: string
}): Product[] {
    let result = [...products]
    if (name) {
        result = result.filter(product => product.name.toLowerCase().includes(name.toLowerCase()))
    }

    console.log("Tag laboratory:", tag.toLowerCase())


    if (tag && category === "Línea del producto") {
        const tagAsKey = tag as keyof typeof filters.productLine;
        result = result.filter(product => {
            console.log("Linea de producto: ", tagAsKey)
            return product.productLine.includes(filters.productLine[tagAsKey])
        })
    } else if (tag && category === "Especies") {
        result = result.filter(product => product.tags.includes(tag))
    } else if (tag && category === "Laboratorio") {
        const tagAsKey = tag as keyof typeof filters.laboratories;
        result = result.filter(product => product.laboratory?.toLowerCase() === filters.laboratories[tagAsKey].toLowerCase())
    }

    return result;
}