import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../../../../assets/images/Logo.svg"
import LinksContainer from "../../../reusable/LinksContainer";
import React from "react";

const LogoAndLinks = () => {

    return (
        <div className={"logo-and-links-container"}>
            <div className="logo-container">
                <Link to={"/"}>
                    <Logo style={{fill: "#000", width: "11rem"}}/>
                </Link>
            </div>
            <div className="links">
                {window.location.pathname === "/" ?
                    <>
                        <Link to="/">Inicio</Link>
                        <LinksContainer tittle={"Productos"} linksName={["Destacados", "Catálogo"]}
                                        linksURL={["/#destacados", "/productos"]}/>
                        <a href="#servicios">Servicios</a>
                        <a href="#nosotros">Nosotros</a>
                    </>
                    :
                    <>
                        <Link to={"/"}>Inicio</Link>
                        <LinksContainer tittle={"Productos"} linksName={["Destacados", "Catálogo"]}
                                        linksURL={["/#destacados", "/productos"]}/>
                        <Link to={"/#servicios"}>Servicios</Link>
                        <Link to={"/#nosotros"}>Nosotros</Link>
                    </>
                }
            </div>
        </div>
    )
}

export default LogoAndLinks;