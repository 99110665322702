import React from "react";
import FooterSocialMediaLogos from "./fragments/FooterSocialMediaLogos";
import FooterRightsReserverd from "./fragments/FooterRightsReserverd";

const FooterBody = () => {

    return (
        <div className={"footer-body"}>
            <FooterSocialMediaLogos/>
            <FooterRightsReserverd/>
        </div>
    )
}

export default FooterBody;