import {FiltersType} from "../types/FiltersType";

export const filters: FiltersType = {
    species:
        {
            porcinos: "Porcinos",
            bovinos: "Bovinos",
            ovinos: "Ovinos",
            aves: "Aves"
        }
    ,
    laboratories:
        {
            avimex: "Avimex",
            bedson: "Bedson",
            calier: "Calier",
            ceva: "Ceva",
            karizoo: "Karizoo",
            lallemand: "Lallemand",
            magapor: "Magapor",
            pancosma: "Pancosma",
            phibro: "Phibro",
            "premezclas de mexico": "Premezclas de México",
            sanfer: "Sanfer",
            "trouw nutrition": "Trouw Nutrition",
        },

    productLine: {
        acidificantes: "Acidificantes",
        aditivos: "Aditivos",
        antibioticos: "Antibióticos",
        antiparasitarios: "Antiparasitarios",
        solubles: "Solubles",
        aromatizantes: "Aromatizantes",
        biologicos: "Biológicos",
        capturantes: "Capturantes",
        coccidicidas: "Coccidicidas",
        endulcorantes: "Endulcorante",
        expectorantes: "Expectorantes",
        hormonales: "Hormonales",
        "inseminacion artificial": "Inseminación Artificial",
        minerales: "Minerales",
        nutricion: "Nutrición",
        prebioticos: "Prebióticos",
        saborizantes: "Saborizantes",
        secuestrantes: "Secuestrantes",
        vitaminas: "Vitaminas",
    }

}