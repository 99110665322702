import {ReactComponent as IconoLupa} from "../../../../../assets/images/lupa.svg"
import React, {useState} from "react";

interface props {
    setActiveFilter: React.Dispatch<React.SetStateAction<{ name: string, tag: string, category: string }>>
    activeFilter: { name: string, tag: string, category: string }
}

const CatalogueInputFilter = ({setActiveFilter, activeFilter}: props) => {


    return <div className={"catalogue-input-container"}>
        <input type="text" placeholder={"Buscar"} className={"catalogue-filter-input"}
               onChange={event => setActiveFilter({...activeFilter, name: event.target.value})}/>
        <IconoLupa className={"catalogue-filter-search-icon desktop"}/>
    </div>


}

export default CatalogueInputFilter;