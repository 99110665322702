import React from "react";

const SpotifyPlayer = () => {

    return (
        <iframe title={"SNI podcast"}
                className={"podcast desktop"}
                src={"https://open.spotify.com/embed/episode/5hMSRotri4f5KBc1QZcLh6?utm_source=generator&theme=0&t=10"}
                allow={"autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"}
                loading={"lazy"}>
        </iframe>
    )
}

export default SpotifyPlayer;