import {Link} from "react-router-dom";
import ShoppingCarIcon from "./ShoppingCarIcon";

const NavIcons = () => {

    return (
        <div className="icons">
            <Link to={"/carrito"}>
                <ShoppingCarIcon/>
            </Link>
        </div>
    )
}

export default NavIcons;