import {ReactComponent as IconoPorcinos} from "../../assets/images/IconoCerdo.svg"
import {ReactComponent as IconoBovinos} from "../../assets/images/IconoVaca.svg"
import {ReactComponent as IconoOvinos} from "../../assets/images/IconoOveja.svg"
import {ReactComponent as IconoAves} from "../../assets/images/IconoGallo.svg"

interface props {
    tags: string[]
    classNameToIcons: string
}

const TagsContainer = ({tags, classNameToIcons}: props) => {

    const iconsSet = new Set();
    iconsSet.add(tags?.map((tag) => {
        if (tag === "porcinos")
            return <IconoPorcinos className={classNameToIcons}/>
        if (tag === "bovinos")
            return <IconoBovinos className={classNameToIcons}/>
        if (tag === "ovinos")
            return <IconoOvinos className={classNameToIcons}/>
        if (tag === "aves")
            return <IconoAves className={classNameToIcons}/>
    }))

    return (
        <>
            {iconsSet}
        </>
    )
}


export default TagsContainer;