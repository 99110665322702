import Sheep from "../../assets/images/sheep.webp";
import "../../assets/styles/App.css"
import {Link} from "react-router-dom";

const NoMatchFound = () => {


    return (
        <div className={"no-match-container"}>
            <img src={Sheep} alt="Oveja mirando al espectador porque se perdió"/>
            <a className={"link-de-regreso"} onClick={() => {window.location.href = "/"}}> Parece que te haz perdido, vuelve a la página principal haciendo click a este mensaje </a>
        </div>
    )
}

export default  NoMatchFound;