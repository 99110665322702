import PhoneSlideMenu from "./layouts/PhoneSlideMenu";
import React, {useEffect, useRef, useState} from "react";
import PhoneStickyMenu from "./layouts/PhoneStickyMenu";

const PhoneNavbar = () => {

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        };

    }, [menuRef]);

    return (
        <>
            <PhoneStickyMenu setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
            <PhoneSlideMenu menuRef={menuRef} isOpen={isMenuOpen} setMenuOpen={setIsMenuOpen}/>
        </>

    )
}

export default PhoneNavbar;