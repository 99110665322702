import {Link, useNavigate} from "react-router-dom";

interface props {
    img: string,
    alt: string,
    text: string
    pathToRedirect: string
}

const NotFoundWildCard = ({img, alt, text, pathToRedirect}: props) => {
    const navigate  = useNavigate();
    navigate(pathToRedirect);
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "15vh 10vw"
        }}>
            <img src={img} alt={alt} style={{width: '20vw', marginBottom: '10rem', borderRadius: "5rem"}}/>
            <Link to={pathToRedirect} style={{fontSize: "2.5rem", color: "white"}}>{text}</Link>
        </div>
    )
}

export default NotFoundWildCard;