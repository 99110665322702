import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CarritoDeCompras} from "../../../../utils/CarritoDeCompras";
import {ReactComponent as Carrito} from "../../../../assets/images/carrito.svg"

const ShoppingCarIcon = () => {
    const shoppingCar = CarritoDeCompras.getCarrito();
    const [numberOfProducts, setNumberOfProducts] = useState(shoppingCar.length)
    useEffect(() => {
        const handleCarritoActualizado = () => {
            const carritoActualizado = CarritoDeCompras.getCarrito();
            setNumberOfProducts(carritoActualizado.length);
        };

        window.addEventListener('carritoActualizado', handleCarritoActualizado);
        handleCarritoActualizado();
        return () => {
            window.removeEventListener('carritoActualizado', handleCarritoActualizado);
        };
    }, []);

    return (
        <div className={"shopping-car-icon-container"}>
            <Link to={"/carrito"} className={""}>
                <Carrito className={"carrito-icon"}/>
                <span className={"products-in-carrito-span"}>{numberOfProducts}</span>
            </Link>
        </div>
    )
}

export default ShoppingCarIcon;