import ContactUsInfo from "../../pages/landing/layouts/ContactUsInfo";
import React from "react";
import FooterHeadCertificates from "./fragments/FooterHeadCertificates";
import FooterCompanyPolicies from "./fragments/FooterCompanyPolicies";
const FooterHead = () => {

    return (
        <div className={"footer-head"}>
            <ContactUsInfo/>
            <FooterHeadCertificates/>
            <FooterCompanyPolicies/>
        </div>
    )
}

export default FooterHead;