import {filters} from './filters'

export class FilterUtility {

    static getCategoryByTag(tag: string): string {

        const processedTag = tag.toLowerCase()
        const filterValues = Object.values(filters)

        for (const specie in filters.species) {
            if (specie === tag) return 'Especies'
        }
        for (const laboratory in filters.laboratories) {
            if (laboratory === tag) return 'Laboratorio'
        }
        for (const productLine in filters.productLine) {
            if (productLine === tag) return 'Línea del producto'
        }
        return '';
    }

    static getTagsByCategory(category: string): string[] {
        const processedCategory = category.toLowerCase()
        if (processedCategory === 'species') return Object.values(filters.species);
        if (processedCategory === 'product line' || category === 'productLine') return Object.values(filters.productLine);
        if (processedCategory === 'laboratories') return Object.values(filters.laboratories);
        return [];
    }


}

