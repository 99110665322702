import React from "react";
import NosotrosCard from "../../../reusable/NosotrosCard";
import NosotrosImg from "../../../../assets/images/landing/Nosotros.webp"
import MisionImg from "../../../../assets/images/landing/Nosotros-01.webp"
import VisionImg from "../../../../assets/images/landing/Nosotros-02.webp"


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ValoresSlide from "./ValoresSlide";
import '@justinribeiro/lite-youtube';


declare namespace JSX {
}

const Nosotros: React.FC = () => {
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        className: "nosotros-carousel",
        variableWidth: false
    };

    const nosotrosDescription = [
        "Salud y Nutrición Integral, fundada en 2003, es una empresa familiar que ha dejado una huella significativa en la región del Bajío. Nos hemos destacado en la distribución de alimentos, medicamentos y aditivos para la producción pecuaria, siempre manteniendo un trato cercano y eficiente con nuestros socios comerciales.",
        "Nuestro compromiso es fuerte y va más allá de la simple distribución. Nos enfocamos en ofrecer productos y servicios de alto valor, colaborando estrechamente con laboratorios internacionales de renombre. Con el espíritu de crecimiento que nos caracteriza, estamos en la búsqueda constante de oportunidades para expandir nuestra red de distribución e importar productos innovadores.",
    ]


    const misionDescription = ["Nuestra misión es contribuir a un mundo más saludable y sustentable; llevando seguridad y bienestar a nuestros clientes a través de un servicio diferenciado y de un equipo comprometido.\n"];

    const visionDescription = ["Ser una empresa consolidada con desarrollo constante en la distribución de productos de nutrición animal, industria farmacéutica y aditivos naturales sustentables, enfocada en las personas, el bienestar común, la excelencia, innovación y servicio de alto nivel.\n"]

    const valoresDescription = ["Responsabilidad:Con nuestros clientes, proveedores y con la empresa.", "Compromiso: Con mis valores, mi familia y mis compañeros.", "Crecimiento: Personal, del mercado y de la empresa."]


    return (
        <section className={"nosotros-section"} id={"nosotros"}>

            <div className="">

                <h2 className={"landing-page-section-tittle"}>Acerca De Nosotros</h2>

                <div className={"nosotros-container"}>
                    <Slider {...settings}>

                        <NosotrosCard tittle={"Nosotros"} description={nosotrosDescription} image={NosotrosImg}
                                      isList={false}/>
                        <NosotrosCard tittle={"Misión"} description={misionDescription} image={MisionImg}
                                      isList={false}/>
                        <NosotrosCard tittle={"Visión"} description={visionDescription} image={VisionImg}
                                      isList={false}/>
                        <div className={"nosotros-card"}>
                            {/*{React.createElement('lite-youtube', {videoId: 'mMdlrcVifNI?si=EJ4oGzhQ2TOSbqGs'})}*/}
                            <iframe
                                src="https://www.youtube.com/embed/mMdlrcVifNI?si=EJ4oGzhQ2TOSbqGs"
                                className={"video-corporativo"}
                                title="Vídeo corporativo" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>

                        </div>
                        <ValoresSlide/>
                    </Slider>
                </div>
            </div>
        </section>
    )
}
export default Nosotros;
