import ProductInShoppingCar from "../../../../types/ProductInShoppingCar";
import {useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {EditQuantityInfo} from "../ShoppingCar";
import ShoppingCarItemsCategoryContainer from "./fragments/ShoppingCarItemsCategoryContainer";
import {getStoredExchangeRate} from "../../../../utils/ExchangeUtility";

interface props {
    carrito: ProductInShoppingCar[];
    setCarrito: React.Dispatch<React.SetStateAction<ProductInShoppingCar[]>>
    setProductToDeleteById: React.Dispatch<React.SetStateAction<string>>
    setEditQuantityInfo: React.Dispatch<React.SetStateAction<EditQuantityInfo | null>>
    isSummaryAccepted: boolean
}

export type ProductLineList = {
    products: ProductInShoppingCar[],
    quantity: number
}

const exchangeRate = getStoredExchangeRate();
const ShoppingCarProductsList = ({
                                     carrito,
                                     setCarrito,
                                     setProductToDeleteById,
                                     setEditQuantityInfo,
                                     isSummaryAccepted
                                 }: props) => {


    const [productsFromProductLine, setProductsFromProductLine] = useState<Map<string, ProductLineList>>(new Map());


    useEffect(() => {
        const newProductsFromProductLine = new Map<string, ProductLineList>();
        carrito.forEach(product => {
            const productLine = product.product.productLine[0];
            if (newProductsFromProductLine.has(productLine)) {
                const productLineList = newProductsFromProductLine.get(productLine);
                if (productLineList) {
                    productLineList.quantity += product.cantidad;
                    productLineList.products.push(product);
                }
            } else {
                newProductsFromProductLine.set(productLine, {quantity: product.cantidad, products: [product]});
            }
        });
        setProductsFromProductLine(newProductsFromProductLine);
    }, [carrito]);

    const sortedProductLines = Array.from(productsFromProductLine.entries())
        .sort((a, b) => a[0].localeCompare(b[0]));


    return (
        <div
            className={isSummaryAccepted ? "shopping-car-products-container shopping-car-disabled" : "shopping-car-products-container"}>
            <div className={"shopping-car-header-container"}>
                <h1 className={"shopping-car-header desktop"}>Productos en el carrito</h1>
                <h1 className={"shopping-car-header phone"}>Mi carrito</h1>
                <Link to={"/productos"}>Seguir comprando</Link>
            </div>
            <small className={"availability-disclaimer"}>Todos los productos están sujetos a disponibilidad</small>
            <div className={"shopping-car-list-container"}>

                {sortedProductLines.map(([productLine, products]) => (
                    <ShoppingCarItemsCategoryContainer key={productLine} products={products} productLine={productLine}
                                                       setProductToDeleteById={setProductToDeleteById}
                                                       setEditQuantityInfo={setEditQuantityInfo}
                                                       exchangeRate={exchangeRate}/>
                ))}
            </div>


        </div>
    )
}

export default ShoppingCarProductsList;