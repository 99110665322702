import {exchangeProcess} from "../../../../../utils/ExchangeUtility";
import {processPrice} from "../../../../../utils/PricesUtility";
import ProductInShoppingCar from "../../../../../types/ProductInShoppingCar";

interface props {
    productInCarrito: ProductInShoppingCar
    exchangeRate:number
}
const ShoppingCarItemPrice = ({productInCarrito, exchangeRate}:props) => {

    return (
        <div className={"purchase-price-details"}>
            <span className={"desktop"}>Precio unitario</span>
            <span className={"purchase-price"}>{productInCarrito.product.price / 100}</span>
            <div className={"large-line desktop"}></div>
            <div className={"purchase-total-container"}>
                <span>Subtotal {productInCarrito.product.currency === "MXN" ? "MXN" : "USD"}: </span>
                {productInCarrito.product.currency === "USD" && <small
                    className={"price-to-mxn"}>MXN: {exchangeProcess(productInCarrito.product.price, productInCarrito.cantidad, exchangeRate)
                } </small>}
                <span
                    className={"purchase-total"}>{processPrice((productInCarrito.product.price * productInCarrito.cantidad) / 100)}</span>
            </div>
        </div>
    )
}

export default ShoppingCarItemPrice;