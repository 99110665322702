import Product from "../types/Product";
import {useState} from "react";


export const productsLoader = async () => {
    try {
        const response = await fetch("/json/FakeDb.json");
        if (!response.ok) {
            throw new Error("Error fetching pro ducts");
        }
        const data = await response.json() as Product[];
        return data;

    } catch (error) {
        console.error("Failed to fetch products", error);
        return [] as Product[];
    }
};
