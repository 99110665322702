import ProductInShoppingCar from "../types/ProductInShoppingCar";

export class CarritoDeCompras {
    static eliminarProducto(idProducto: string): void {
        let carrito = this.getCarrito();
        carrito = carrito.filter(producto => producto.product.id !== idProducto);
        this.guardarCarrito(carrito);
    }

    static actualizarCantidadDeProduct(idProducto: string, cantidadNueva:number) {
        let carrito = this.getCarrito();
        carrito.map(productInCarrito => {
            if (productInCarrito.product.id === idProducto) productInCarrito.cantidad = cantidadNueva
        })
        this.guardarCarrito(carrito);
    }

    static guardarProducto(product: ProductInShoppingCar): boolean {
        const carrito = this.getCarrito();
        let inCarrito = false;
        carrito.map(producto => {
            if (producto.product.id === product.product.id) inCarrito = true;
        })
        if (!inCarrito) {
            carrito.push(product);
            this.guardarCarrito(carrito)
            return true;
        }
    return false;
    }

    public static getCarrito(): ProductInShoppingCar[] {
        const carritoItem = localStorage.getItem("carrito");
        if (carritoItem) {
            return JSON.parse(carritoItem);
        }
        return []
    }

    static vaciarCarrito(): void {
        this.guardarCarrito([])
    }


    private static guardarCarrito(carrito: ProductInShoppingCar[]): void {
        localStorage.setItem('carrito', JSON.stringify(carrito));
        window.dispatchEvent(new Event('carritoActualizado'));
    }
}
