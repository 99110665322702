import React, {useEffect, useState} from "react";

import "../../../assets/styles/App.css"
import "../../../assets/styles/landing.css"
import Nosotros from "./layouts/Nosotros";
import Product from "../../../types/Product";
import SpeciesSection from "./layouts/SpeciesSection";
import Carousel from "./layouts/Carousel";
import Card from "../../reusable/Card";

import LaboratoriesBanner from "./layouts/LaboratoriesBanner";
import ServicesSection from "./layouts/ServicesSection";


const Landing = () => {
    const [carouselProducts, setCarouselProducts] = useState<any>([])
    console.log("Renderizado")
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetch("/json/FakeDb.json");
                if (!response.ok) {
                    throw new Error("Error fetching pro ducts");
                }
                const responseJson = await response.json() as Product[];
                setCarouselProducts([
                    <Card product={responseJson[50]} key={responseJson[50].id}/>,
                    <Card product={responseJson[5]} key={responseJson[5].id}/>,
                    <Card product={responseJson[8]} key={responseJson[8].id}/>,
                    <Card product={responseJson[7]} key={responseJson[7].id}/>,
                    <Card product={responseJson[4]} key={responseJson[4].id}/>,
                    <Card product={responseJson[2]} key={responseJson[2].id}/>,
                    <Card product={responseJson[3]} key={responseJson[3].id}/>,
                ])
            } catch (error) {
                console.error("Failed to fetch products", error);
            }
        };
        getData();
    }, []);

    return (
        <div className="landing">
            <SpeciesSection/>
            {/*<LaboratoriesBanner/>*/}
            <Carousel cards={carouselProducts}/>
            <ServicesSection/>
            <Nosotros/>
        </div>
    )
}

export default Landing;

