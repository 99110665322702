import React from "react";
import {ReactComponent as FacebookLogo} from "../../../../assets/images/FacebookLogo.svg";
import {ReactComponent as InstagramLogo} from "../../../../assets/images/IgLogo.svg";
import {ReactComponent as TiktokLogo} from "../../../../assets/images/TiktokLogo.svg";
const FooterSocialMediaLogos = () => {

    return (
        <ul className={"social-media-logos"}>
            <li>
                <a href="https://www.facebook.com/snisaludanimal" rel={"noopener noreferrer"} target={"_blank"}>
                    <FacebookLogo/>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/snisaludanimal/" rel={"noopener noreferrer"}
                   target={"_blank"}>
                    <InstagramLogo/>
                </a>
            </li>
            <li>
                <a href="https://www.tiktok.com/@snintegral" rel={"noopener noreferrer"} target={"_blank"}>
                    <TiktokLogo/>
                </a>
            </li>
        </ul>
    )
}

export default FooterSocialMediaLogos;