import React from "react";

const FooterRightsReserverd = () => {

    return (
        <div className={"footer-body-rights"}>
            <small>{new Date().getUTCFullYear()} Todos los derechos reservados</small>
            <small>Salud y Nutrición Integral</small>
        </div>
    )
}

export default FooterRightsReserverd;