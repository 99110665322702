import ESRLogo from "../../../../assets/images/landing/logoESR.webp";
import SenesicaLogo from "../../../../assets/images/landing/Senasica.webp";
import SaderLogo from "../../../../assets/images/landing/sader.svg";
import React from "react";

const FooterHeadCertificates = () => {

    return (
        <>
            <a href="https://sustentarse.com.mx/distintivo-empresa-socialmente-responsable-esr/"
               className={"esr-logo enlace-de-certificacion"}>
                <img src={ESRLogo} alt="Logo de certificación Empresa Socialmente Responsable"
                     className={"esr-logo certificacion"}/>
            </a>
            <a href="https://www.gob.mx/senasica" className={"enlace-de-certificacion senesica-logo"}>
                <img src={SenesicaLogo} alt="" className={"senesica-logo certificacion"}/>
            </a>
            <a href="https://www.gob.mx/agricultura" className={"enlace-de-certificacion sader-logo"}>
                <img src={SaderLogo} alt="" className={"sader-logo certificacion"}/>
            </a>

        </>
    )
}

export default FooterHeadCertificates;