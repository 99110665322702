import {processPrice} from "../../../../utils/PricesUtility";

interface PurchaseSummaryInterface {
    setSummaryChecked: React.Dispatch<React.SetStateAction<boolean>>
    isAValidShoppingCart: boolean
    total: number
}


const PurchaseSummary = ({
                             setSummaryChecked,
                             isAValidShoppingCart,
                             total
                         }: PurchaseSummaryInterface) => {


    return (
        <aside className={"purchase-summary-container"}>
            <div className={"purchase-summary"}>
                <h2 className={"desktop"}>Resumen del pedido</h2>
                <div className={"purchase-summary-total"}>
                    <span>Total en MXN: </span>
                    <span>${processPrice(total.toFixed(2))}</span>
                </div>
                <small className={"purchase-summary-legend"}>Al poder variar los precios y el tipo de cambio el total es
                    un estimado, el
                    costo específico se podrá ver en
                    factura.</small>
                <button className={isAValidShoppingCart ? "" : "disabled-button"}
                        onClick={() => setSummaryChecked(true)}>Continuar
                </button>
            </div>
        </aside>
    )
}

export default PurchaseSummary;
