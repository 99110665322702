import {ProductLineList} from "../ShoppingCarProductsList";
import {ReactComponent as Flecha} from "../../../../../assets/images/flecha.svg"
import {useState} from "react";
import {EditQuantityInfo} from "../../ShoppingCar";
import ShoppingCarItem from "./ShoppingCarItem";

interface props {
    productLine: string,
    products: ProductLineList,
    setProductToDeleteById: React.Dispatch<React.SetStateAction<string>>
    setEditQuantityInfo: React.Dispatch<React.SetStateAction<EditQuantityInfo | null>>
    exchangeRate: number
}

const ShoppingCarItemsCategoryContainer = ({
                                        productLine,
                                        products,
                                        setProductToDeleteById,
                                        setEditQuantityInfo,
                                        exchangeRate
                                    }: props) => {
    const [isHide, setIsHide] = useState(false)

    return (
        <div id={productLine}>
            <div className={"product-header-container"} onClick={() => setIsHide(!isHide)}>
                <span className={"product-header-productline"}>{productLine}</span>
                <div>
                        <span
                            className={isHide ? "product-header-quantity active-text" : "product-header-quantity"}>{products.quantity}</span>
                    <Flecha className={isHide ? "sc-arrow sc-arrow-active" : "sc-arrow"}/>
                </div>
            </div>
            {products.products.map((product, index) => (
                <ShoppingCarItem key={product.product.id} productInCarrito={product}
                                 setProductToDeleteById={setProductToDeleteById}
                                 setEditQuantityInfo={setEditQuantityInfo}
                                 isHide={isHide}
                                 exchangeRate={exchangeRate}
                />
            ))}

        </div>
    )
}

export default ShoppingCarItemsCategoryContainer;