import BurgerIconBtn from "./fragments/BurgerIconBtn";
import ShoppingCarIcon from "./fragments/ShoppingCarIcon";
import {ReactComponent as Logo} from "../../../assets/images/Logo.svg"
import React from "react";

interface props {
    isMenuOpen: boolean;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PhoneStickyMenu = ({isMenuOpen, setIsMenuOpen}: props) => {

    return (
        <nav className={"phone-nav"}>
            <Logo className={"phone-logo"}
                  aria-label={"Logo de Salud Y Nutrición Integral, abreviado como S-N-I"}
                  onClick={() => window.location.href = "/"}/>

            <div className="icons">
                <BurgerIconBtn isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
                <ShoppingCarIcon/>
            </div>
        </nav>
    )
}

export default PhoneStickyMenu;