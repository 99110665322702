import {ReactComponent as CrecimientoIcon} from "../../../../assets/images/crecimiento.svg"
import {ReactComponent as CompromisoIcon} from "../../../../assets/images/compromiso.svg"
import {ReactComponent as ResponsabilidadIcon} from "../../../../assets/images/responsabilidad.svg"
import ValuesImg from "../../../../assets/images/landing/Nosotros-03.webp"

const ValoresSlide = () => {


    return (
        <div className={"nosotros-card"}>
            <div className={"nosotros-info"}>
                <strong className={'nosotros-info-title'}>
                    <h2>Valores</h2>
                </strong>

                <div className={"values-value-tittle"}>
                    <ResponsabilidadIcon className={"values-icon"}/>
                    <span>
                        <strong>
                            Responsabilidad
                        </strong>
                    </span>
                </div>

                <p className={"values-paragraph"}>Responsabilidad: Con nuestros clientes, proveedores y con la
                    empresa.</p>

                <div className={"values-value-tittle"}>
                    <CompromisoIcon className={"values-icon"}/>
                    <span>
                        <strong>
                            Compromiso
                        </strong>
                    </span>
                </div>
                <p className={"values-paragraph"}>Con mis valores, mi familia y mis compañeros.</p>

                <div className={"values-value-tittle"}>
                    <CrecimientoIcon className={"values-icon"}/>
                    <span>
                        <strong>
                            Crecimiento
                        </strong>
                    </span>
                </div>
                <p className={"values-paragraph"}>Personal, del mercado y de la empresa.</p>
            </div>

            <img src={ValuesImg}
                 alt="Colaborador ejemplar con una buena actitud que refleja los valores de la empresa"/>
        </div>
    )
}

export default ValoresSlide;