import TagsContainer from "../../../reusable/TagsContainer";
import WhatsappIcon from "../../../../assets/images/WhatsappIcon.svg";
import {ReactComponent as OlaVertical} from "../../../../assets/images/OndaVertical.svg"
import {ReactComponent as Estrella} from "../../../../assets/images/estrella.svg"
import {ReactComponent as Foco} from "../../../../assets/images/foco.svg"
import Product from "../../../../types/Product";

interface props {
    mainProduct: Product
}

const ProductInfoHeader = ({mainProduct}: props) => {

    const whatsappMessage = `https://wa.me/5213521174644?text=¡Hola! Un gusto. ¿Podrías compartirme la ficha del siguiente producto: *${mainProduct.name}* en la presentación de *${mainProduct.appaerance}*?. Gracias`;
    return (
        <article className="product-info-header">
            <figure className="product-image">
                <img
                    src={mainProduct.imageURL}
                    alt="Producto"/>

                <OlaVertical className={mainProduct?.isPremium ? "ola-vertical blacked" : "ola-vertical"}/>
                {mainProduct.isPremium ? <Estrella className={"product-prestige"}/> :
                    <Foco className={"product-prestige"}/>}
            </figure>


            <div className="product-details">
                <h1 className="product-name">{mainProduct.name}</h1>
                <div className={"product-line-container"}>
                    {mainProduct.productLine.map(line => (
                        <p className={"product-line"}>{line}</p>
                    ))}

                </div>
                <div className={"laboratory-and-recommended-use-container"}>
                    <strong className={'recommended-use'}>
                        <p> USO RECOMENDADO EN: </p>
                    </strong>
                    <div className={"recommended-use-img-container"}>
                        <TagsContainer tags={mainProduct!.tags} classNameToIcons={""}/>
                    </div>
                    <strong className={'laboratory'}>
                        <p>Laboratorio: </p>
                    </strong>

                    <p className={"laboratory-name"}>{mainProduct.laboratory}</p>

                    <strong className={'content'}>
                        <p>Contenido:</p>
                    </strong>

                    <p className={"product-content"}>{mainProduct.amountOfContent} {mainProduct.unitOfMeasurement}</p>
                </div>
                <a href={whatsappMessage} target={"_blank"} className="consultar-btn"> <img
                    src={WhatsappIcon}
                    alt=""
                    className={"whatsApp-icon"}/>Consultar
                    Ficha</a>
            </div>
        </article>
    )
}

export default ProductInfoHeader;