import Cerdo from "../../../../assets/images/landing/CERDO.webp";
import Vaca from "../../../../assets/images/landing/VACA.webp";
import Oveja from "../../../../assets/images/landing/OVEJA.webp";
import Gallo from "../../../../assets/images/landing/GALLO.webp";
import React from "react";
import {ReactComponent as IconoPorcinos} from "../../../../assets/images/IconoCerdo.svg"
import {ReactComponent as IconoBovinos} from "../../../../assets/images/IconoVaca.svg"
import {ReactComponent as IconoOvinos} from "../../../../assets/images/IconoOveja.svg"
import {ReactComponent as IconoAves} from "../../../../assets/images/IconoGallo.svg"
import {useNavigate} from "react-router-dom";

const SpeciesSection = () => {
    const navigate = useNavigate();
    return (
        <main className="main" id="main">
            <div className={"imgs-container"}>
                <div className={"porcinos-container"} onClick={() => navigate("productos/porcinos")}>
                    <img src={Cerdo} className={"animal-img"} alt="Imágen de un pequeño cerdito"/>
                    <div className={"icon-and-tittle-container"}>
                        <IconoPorcinos className={"animal-icon"}/>
                        <h2 className={"animal-tittle"}>Porcinos</h2>
                    </div>
                </div>
                <div className={"bovinos-container"} onClick={() => navigate("productos/bovinos")}>
                    <img src={Vaca} alt="Imagen de una linda vaca"/>
                    <div className={"icon-and-tittle-container"}>
                        <IconoBovinos className={"animal-icon"}/>
                        <h2 className={"animal-tittle"}>Bovinos</h2>
                    </div>
                </div>

                <div className={"ovinos-container"} onClick={() => navigate("productos/ovinos")}>
                    <img src={Oveja} alt="Imagen de una linda oveja"/>
                    <div className={"icon-and-tittle-container"}>
                        <IconoOvinos className={"animal-icon"}/>
                        <h2 className={"animal-tittle"}>Ovinos</h2>
                    </div>
                </div>

                <div className={"aves-container"} onClick={() => navigate("productos/aves")}>
                    <img src={Gallo} alt="Imagen de un lindo gallo"/>
                    <div className={"icon-and-tittle-container"}>
                        <IconoAves className={"animal-icon"}/>
                        <h2 className={"animal-tittle"}>Aves</h2>
                    </div>
                </div>

            </div>
        </main>
    )
}

export default SpeciesSection;