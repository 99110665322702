import * as React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import App from "./App";
import Landing from "./components/pages/landing/Landing";
import Catalogue from "./components/pages/catalogue/Catalogue";
import NoMatchFound from "./components/reusable/NoMatchFound";
import ProductInfoPage from "./components/pages/productInfo/ProductInfoPage";
import ShoppingCar from "./components/pages/shoppingcar/ShoppingCar";
import {productsLoader} from "./utils/productsLoader";
import {createRoot} from "react-dom/client";

const router = createBrowserRouter([

    {
        path: "/",
        element: <App/>,
        loader:productsLoader,
        children: [
            {
                index: true,
                path: "/",
                element: <Landing/>,
                loader: productsLoader
            },

            {
                path: "/productos",
                element: <Catalogue/>,
                loader: productsLoader
            },
            {
                path: "/productos/:tag",
                element: <Catalogue/>,
                loader: productsLoader
            },
            {
                path: "/producto/:productId",
                element: <ProductInfoPage/>,
                loader: productsLoader

            },
            {
                path: "/carrito",
                element: <ShoppingCar/>
            },
            {
                path: "*",
                element: <NoMatchFound/>
            }

        ],
    },
]);


createRoot(document.getElementById("root") as Element).render(
    <RouterProvider router={router}/>
);

