import React, {useRef, useState} from "react";
import Product from "../../../../../types/Product";
import {ReactComponent as EstrellaIcon} from "../../../../../assets/images/estrella.svg"
import {ReactComponent as FocoIcon} from "../../../../../assets/images/foco.svg"
import TooltipImg from "../../../../../assets/images/tooltip.png"
import {ReactComponent as OndaVertical} from "../../../../../assets/images/OndaVertical.svg"
import {Link} from "react-router-dom";
import {CarritoDeCompras} from "../../../../../utils/CarritoDeCompras";
import ProductInShoppingCar from "../../../../../types/ProductInShoppingCar";
import TagsContainer from "../../../../reusable/TagsContainer";

interface props {
    product: Product
    showAd: string
    setShowAd: React.Dispatch<React.SetStateAction<string>>
}

const CatalogueProduct = ({product, showAd, setShowAd}: props) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const [showPriceDisclaimer, setShowPriceDisclaimer] = useState(false);
    const [isChoosingQuantity, setIsChoosingQuantity] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [quantity, setQuantity] = useState(0);

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            quantity ? handleSaveProduct() : setIsChoosingQuantity(false)
        }
    }

    const handleMinus = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        if (quantity < 1) {
            return;
        }
        setQuantity(quantity - 1);
    }

    const handleSaveProduct = () => {
        if (quantity) {
            const productoDeCarrito: ProductInShoppingCar = {
                product: product,
                cantidad: quantity
            }
            const savedProduct = CarritoDeCompras.guardarProducto(productoDeCarrito);
            setQuantity(1);
            setIsChoosingQuantity(false);

            savedProduct ? setShowAd("saved") : setShowAd("rejected");

            setTimeout(() => {
                setShowAd("")
            }, 2000);
        }
    }


    return (
        <div className="product">
            <div className={"product-top-body"}>
                <Link to={"/producto/" + product?.id}>
                    <img src={product.imageURL} alt="" loading={"lazy"}/>
                </Link>
            </div>
            <div className={product.isPremium ? "product-down-body-premium" : "product-down-body"}>
                <strong className={showTooltip ? "product-tittle under-border" : "product-tittle"}>
                    <Link to={"/producto/" + product?.id}>
                        {product?.name}
                    </Link>
                </strong>

                {showTooltip &&
                    <span className="tooltip">Click en el nombre para más detalles</span>}
                <span className={"catalogue-product-type"}>
                    {product?.productLine[0]}
                </span>
                <span className={"catalogue-product-content"}>
                     {product.amountOfContent} {product?.unitOfMeasurement}
                </span>
                <span className={"catalogue-product-price"} onMouseOver={() => setShowPriceDisclaimer(true)}
                      onMouseOut={() => setShowPriceDisclaimer(false)}>
                    {product.currency} ${product.price / 100}*
                </span>

                {showPriceDisclaimer &&
                    <small className={"catalogue-price-disclaimer"}>*Precio de referencia, este puede variar</small>}

                <button className={"add-button"} onClick={() => {
                    setIsChoosingQuantity(!isChoosingQuantity)
                    if (inputRef.current) inputRef.current.focus();
                }}>+ AGREGAR
                </button>

                <div
                    className={isChoosingQuantity ? "add-product-input-container quantity-active" : "add-product-input-container"}>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         onClick={handleMinus}
                         stroke="currentColor" className="minus-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14"/>
                    </svg>

                    <input ref={inputRef} type="number" min={1} placeholder={"Piezas"} value={quantity}
                           onChange={event => setQuantity(parseInt(event.target.value))}
                           onKeyDown={event => handleEnter(event)}/>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="plus-icon" onClick={e => setQuantity(quantity + 1)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                    </svg>

                    {
                        quantity ?
                            <svg onClick={handleSaveProduct}
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="quantity-choosen-btn valid-quantity">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                            </svg>
                            :
                            <svg onClick={() => setIsChoosingQuantity(false)}
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="quantity-choosen-btn invalid-quantity">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                            </svg>

                    }
                </div>

                <div className={"tags"}>
                    <TagsContainer tags={product.tags} classNameToIcons={""}/>
                </div>
                <img src={TooltipImg} alt="descripción emergente(Tooltip)"
                     className={"tooltip-icon"}
                     onMouseOver={() => setShowTooltip(true)}
                     onMouseOut={() => setShowTooltip(false)}
                />

                <OndaVertical className={"wave-icon"}/>
                {
                    product?.isPremium ? <EstrellaIcon className={"product-type-icon estrella"}/> :
                        <FocoIcon className={"product-type-icon foco"}/>
                }

            </div>
        </div>
    )
}

export default CatalogueProduct;