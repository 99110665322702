import "./carousel.css"
import {ReactComponent as ArrowIcon} from "../../../../assets/images/flecha.svg"
import React, {RefObject, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";

interface props {
    cards: any[]
}

const resolution = window.innerWidth

function getRemValue(): number {
    const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return remInPixels;
}

function getRemsToTranslateOnResolution(screenResolution: number): number {
    if (screenResolution >= 1900) {
        return 36.625
    } else if (screenResolution >= 1300) {
        return 45
    } else if (screenResolution >= 750) {
        return 47.5
    } else {
        return 66.5
    }
}

const Carousel = ({cards}: props) => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const itemWidth = getRemValue() * getRemsToTranslateOnResolution(resolution)

    function getSlidesToGoOnResolution(screenResolution: number): number {

        if (screenResolution >= 1900) {
            return cards.length - 5
        } else if (screenResolution >= 1300) {
            return cards.length - 4
        } else if (screenResolution >= 750) {
            return cards.length - 3
        } else {
            return cards.length - 1
        }
    }

    function handleSlideBack() {
        if (carouselRef.current) {
            const currentScrollLeft = carouselRef.current.scrollLeft
            carouselRef.current.scroll({left: currentScrollLeft - itemWidth, behavior: 'smooth'})
            if (carouselRef.current.scrollLeft - itemWidth <= 0) {
                disableLeftArrow()
            }
        }
        setScrollLeft(scrollLeft - itemWidth)
    }

    function handleSlideForward() {
        if (carouselRef.current) {
            const currentScrollLeft = carouselRef.current.scrollLeft
            //carouselRef.current.scrollLeft += itemWidth
            toggleRightArrow(shouldDisplayRightArrowAfterSlide(itemWidth * 1.2))
            carouselRef.current.scroll({left: currentScrollLeft + itemWidth, behavior: 'smooth'})
            setScrollLeft(scrollLeft + itemWidth)
        }

    }

    function shouldDisplayRightArrowAfterSlide(extraMargin: number): boolean {
        if (carouselRef.current)
            return carouselRef.current.offsetWidth + carouselRef.current.scrollLeft + extraMargin < carouselRef.current.scrollWidth
        return false
    }

    function toggleRightArrow(display: boolean) {
        const carouselRightArrow = document.querySelector(".testing-carousel-right-arrow");
        if (display && carouselRightArrow) {
            carouselRightArrow.classList.remove('disabled-arrow')
        } else if (carouselRightArrow) {
            carouselRightArrow.classList.add('disabled-arrow')
        }
    }

    function disableLeftArrow() {
        const carouselRightArrow = document.querySelector(".testing-carousel-left-arrow");
        if (carouselRightArrow)
            carouselRightArrow.classList.add('disabled-arrow')

    }

    function handleMouseDown(event: React.MouseEvent) {
        setIsDragging(true);
        if (carouselRef.current) {
            setStartX(event.pageX - carouselRef.current.offsetLeft);
            setScrollLeft(carouselRef.current.scrollLeft)
        }
    }

    const handleTouchStart = (e: React.TouchEvent) => {
        setIsDragging(true);
        if (carouselRef.current) {
            setStartX(e.touches[0].pageX - carouselRef.current.offsetLeft);
            setScrollLeft(carouselRef.current.scrollLeft);
        }
    };

    function handleMouseUp(event: React.MouseEvent) {
        setIsDragging(false);


    }

    function handleTouchEnd(event: React.TouchEvent) {
        setIsDragging(false);

    }

    function handleMouseLeave(event: React.MouseEvent) {
        setIsDragging(false)
    }

    function handleMouseMove(event: React.MouseEvent) {
        if (!isDragging || !carouselRef.current) return;
        event.preventDefault();
        const x = event.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Ajusta según tu preferencia
        carouselRef.current.scrollLeft = scrollLeft - walk;
        toggleRightArrow(shouldDisplayRightArrowAfterSlide(itemWidth * 0.85))
    }

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging || !carouselRef.current) return;
        e.preventDefault();
        const x = e.touches[0].pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        carouselRef.current.scrollLeft = scrollLeft - walk;
    };


    useEffect(() => {
        if (carouselRef.current && carouselRef.current.scrollLeft <= 5) {
            toggleRightArrow(true);
        }

    }, [cards]);

    return (
        <section className={"testing-section"} id={"destacados"}>
            <h2 className={"landing-page-section-tittle"}>Productos destacados</h2>

            <div className={"carousel-wrapper"}>

                {carouselRef.current &&
                    <ArrowIcon
                        className={carouselRef.current.scrollLeft > (itemWidth / 1.2) ? "testing-carousel-left-arrow" : "testing-carousel-left-arrow disabled-arrow"}
                        onClick={handleSlideBack}/>
                }
                <div className={"testing-carousel-container"} ref={carouselRef}>
                    <div className={"testing-carousel-card-container"}
                         onMouseDown={handleMouseDown}
                         onTouchStart={handleTouchStart}
                         onMouseMove={handleMouseMove}
                         onTouchMove={handleTouchMove}
                         onMouseUp={handleMouseUp}
                         onTouchEnd={handleTouchEnd}
                         onMouseLeave={handleMouseLeave}>
                        {cards}
                    </div>
                </div>

                {/*className={carouselRef.current.offsetWidth + carouselRef.current.scrollLeft + (itemWidth / 1.2) > carouselRef.current.scrollWidth ? "testing-carousel-right-arrow disabled-arrow" : "testing-carousel-right-arrow"}*/}
                {
                    carouselRef.current &&
                    <ArrowIcon
                        className={"testing-carousel-right-arrow disabled-arrow"}
                        onClick={handleSlideForward}/>
                }

            </div>
            <Link to={"/productos"} className={"link-to-products"}>
                <button className={"outstanding-section-button"}>Ver más productos</button>
            </Link>
        </section>
    )
}

export default Carousel;