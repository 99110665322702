import React from 'react';
import {Link} from "react-router-dom";

interface props {
    menuRef: React.RefObject<HTMLDivElement>
    isOpen: boolean;
    setMenuOpen: (open: boolean) => void;
}

const PhoneSlideMenu = ({menuRef, isOpen, setMenuOpen}: props) => {
    const handleClickOnHref = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        console.log(event.type.toString())
        setMenuOpen(false);
        if (event.target instanceof HTMLAnchorElement) {
        }
    }

    return (
        <div ref={menuRef} onClick={event => handleClickOnHref} className={`phone-menu ${isOpen ? 'open' : ''}`}>
            <span className={"phone-menu-link-tittle"}>Secciones</span>
            {window.location.pathname === "/" ?
                <ul className={"phone-menu-link-list"}>
                    <li>
                        <a className={"phone-menu-link"} onClick={() => setMenuOpen(false)} href="#main">Inicio</a>
                    </li>
                    <li>
                        <Link className={"phone-menu-link"} onClick={() => window.location.href = "/#productos"}
                              to={"/#productos"}>Productos</Link>
                    </li>
                    <li>
                        <a className={"phone-menu-link"} href="#servicios">Servicios</a>
                    </li>
                    <li>
                        <a className={"phone-menu-link"} href="#nosotros">Nosotros</a>
                    </li>
                </ul>
                :

                <ul className={"phone-menu-link-list"}>
                    <li>
                        <a className={"phone-menu-link"} onClick={() => {
                            setMenuOpen(false)
                            window.location.href = "/#main"
                        }}> Inicio</a>
                    </li>
                    <li>
                        <a className={"phone-menu-link"}
                           onClick={() => window.location.href = "/#servicios"}>Servicios</a>
                    </li>
                    <li>
                        <a className={"phone-menu-link"}
                           onClick={() => window.location.href = "/#nosotros"}>Nosotros</a>
                    </li>
                </ul>
            }


            <span className={"phone-menu-link-tittle"}>Productos</span>
            <ul className={"phone-menu-pages-list"}>
                <li>
                    <Link className={"phone-menu-link finding"} to={"/#destacados"}
                          onClick={(event) => handleClickOnHref}>Destacados</Link>
                </li>
                <li>
                    <Link className={"phone-menu-link"} to={"/productos"}>Catálogo</Link>
                </li>
            </ul>

            <button className="closeButton" onClick={() => setMenuOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6 close-menu-btn">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
    );
};

export default PhoneSlideMenu;
