import ProductInShoppingCar from "../../../../../types/ProductInShoppingCar";
import {ReactComponent as TrashIcon} from '../../../../../assets/trash-icon.svg'
import {ChangeEvent, useEffect, useId, useState} from "react";

interface props {

    newProductQuantity: number
    handleQuantityChange: (event: ChangeEvent<HTMLInputElement>) => void
    handleDeleteProduct: () => void
    handleSubtract: () => void
    handlePlus: () => void
}

const ShoppingCarItemInput = ({
                                  handlePlus,
                                  handleSubtract,
                                  handleQuantityChange,
                                  newProductQuantity,
                                  handleDeleteProduct,

                              }: props) => {





    return (
        <div className={"purchase-inputs"}>

            {
                newProductQuantity < 2 ?
                    <TrashIcon
                        className={`shopping-car-delete-icon cls-1 cls-2`}
                        onClick={handleDeleteProduct}/>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="subtract" onClick={handleSubtract}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14"/>
                    </svg>
            }


            <input type="number" min={0} className={"purchase-quantity-input"}
                   placeholder={"Min 1"}
                   defaultValue={newProductQuantity}
                   onChange={handleQuantityChange}
                   value={newProductQuantity}
            />

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 onClick={handlePlus}
                 stroke="currentColor" className="plus">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
            </svg>

        </div>
    )
}

export default ShoppingCarItemInput;