export function processPrice(subtotal: number | string): string {
    let subtotalString = typeof subtotal === "string" ? subtotal : subtotal.toString();

    /*const decimals = subtotalString.substring(subtotalString.length - numberOfDecimals);*/
    const dotIndex = subtotalString.indexOf(".");

    if (dotIndex === -1) {
        return addCommaEveryThreePositions(subtotalString)
    } else {
        let subtotalWithoutDecimals = subtotalString.substring(0, dotIndex)
        const dotAndDecimals = subtotalString.substring(dotIndex);
        subtotalWithoutDecimals = addCommaEveryThreePositions(subtotalWithoutDecimals);
        return subtotalWithoutDecimals + dotAndDecimals;
    }
}

export function addCommaEveryThreePositions(subtotal: string): string {
    let result = '';
    let counter = 0;

    // Recorre la cadena desde el final al inicio
    for (let i = subtotal.length - 1; i >= 0; i--) {
        result = subtotal[i] + result;
        counter++;

        // Añade una coma cada tres caracteres, excepto al final
        if (counter % 3 === 0 && i !== 0) {
            result = ',' + result;
        }
    }

    return result;
}
