interface props {
    title: string,
    imgSrc: string,
    description: string,
}

const Service = ({title, imgSrc, description}: props) => {

    return (
        <div className={"landing-services-service"}>
            <div className="landing-services-service-container">
                <div className={'loading-services-service-front'} style={{backgroundImage: `url(${imgSrc})`}}></div>

                <div className={'loading-services-service-back'}>
                    <div className={'loading-services-service-content'}>
                        <h3>
                            {title}
                        </h3>
                        <p className={"landing-services-service-info"}>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;