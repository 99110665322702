import React from "react";

const FooterCompanyPolicies = () => {

    const descargarAvisoDePrivacidad = async () => {
        fetch('AvisoDePrivacidad.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'AvisoDePrivacidad.pdf';
                alink.click();
            })
        })
    }
    const downloadCodigoDeEtica = async () => {
        try {
            const response = await fetch('CodigoDeEtica.pdf');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'codigo-de-etica-oficial-sni.pdf';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    return (
        <div className="footer-company-policies">
            <button onClick={descargarAvisoDePrivacidad} className={"aviso-de-privacidad"}>
                Aviso de privacidad
            </button>

            <button onClick={downloadCodigoDeEtica} className={"codigo-de-etica"}>
                Código de ética
            </button>
        </div>
    )
}

export default FooterCompanyPolicies;