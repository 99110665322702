import {useLoaderData, useParams} from "react-router-dom";
import "../../../assets/styles/App.css"
import "../../../assets/styles/productDetails.css"
import OutOfStockImg from "../../../assets/images/out-of-stock-img.webp"
import Product from "../../../types/Product";
import NotFoundWildCard from "../../reusable/NotFoundWildCard";
import ProductInfoHeader from "./fragments/ProductInfoHeader";
import ProductInfoBody from "./fragments/ProductInfoBody";

const ProductInfoPage = () => {
    const {productId} = useParams()
    const products = useLoaderData() as Product[]
    console.log("Renderizado")

    const mainProduct = products.find(product => product.id === productId)
    if (mainProduct === undefined) {
        return <NotFoundWildCard img={OutOfStockImg} alt={"Imagen de lupa"}
                                 text={"Producto no encontrado, favor de buscar alguno disponible. Click aquí"}
                                 pathToRedirect={"/productos"}/>
    }


    return <main className={"product-info-content"}>
        <ProductInfoHeader mainProduct={mainProduct}/>
        <ProductInfoBody mainProduct={mainProduct}/>
    </main>
}

export default ProductInfoPage;