import Product from "../../../../types/Product";

const ProductInfoBody = ({mainProduct}: { mainProduct: Product }) => {

    return (
        <ul className="product-info-body">
            <li>
                <h2>Descripción</h2>
                <p>{mainProduct.description}</p>
            </li>
            <li>
                <h2>Instrucciones de uso y vía de administración</h2>
                <p>{mainProduct.instructions}</p>

            </li>
            <li>
                <h2>Ingredientes</h2>
                <p>{mainProduct.ingredients}</p>
            </li>
            <li>
                <h2>Dósis</h2>
                <p>{mainProduct.usage}</p>
            </li>
            <li>
                <h2>Precauciones</h2>
                <p>{mainProduct.cautions ? mainProduct.cautions : "Las que el médico veterinario señale"}</p>
            </li>

        </ul>
    )
}

export default ProductInfoBody;