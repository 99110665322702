import React from "react";
import FooterBody from "./layouts/FooterBody";
import FooterHead from "./layouts/FooterHead";


const Footer = () => {

    return (
        <footer>
            <FooterHead/>
            <FooterBody/>
        </footer>
    )
}

export default Footer;