import React, {useState} from "react";
import "../../../../assets/styles/landing.css"
import {ReactComponent as Flecha} from "../../../../assets/images/flecha.svg";


const ContactUsInfo = () => {

    const [showInfo, setShowInfo] = useState(false);
    let flechaClasses;
    let footerUpContactUsSpan;
    if (showInfo) {
        flechaClasses = "footer-up-arrow footer-up-arrow-active"
        footerUpContactUsSpan = "footer-up-ubicanos footer-up-ubicanos-active "
    } else {
        flechaClasses = "footer-up-arrow";
        footerUpContactUsSpan = "footer-up-ubicanos"
    }


    return (
        <div className={"footer-up-contact-us"}>
            <div onClick={() => setShowInfo(!showInfo)}>
             <span className={footerUpContactUsSpan}>
                        Contáctanos
             </span>
                <Flecha className={flechaClasses}/>
            </div>
            <div
                className={showInfo ? "footer-up-contact-us-info-container footer-info-active" : "footer-up-contact-us-info-container"}>
                <div>
                    <div className={"footer-up-ubicanos-info-div"}>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={.5}
                             stroke="currentColor" className="w-6 h-6 footer-info-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"/>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"/>
                        </svg>

                        <span>
                                <a href="https://goo.gl/maps/fR6B63DYQECUw2FN7" rel={"noopener noreferrer"}
                                   target={"_blank"}>
                                    {/*Av Padre Hidalgo #895, Centro, 36910 Santa Ana Pacueco, Gto*/}
                                    Av Padre Hidalgo #895
                                </a>
                            </span>

                    </div>
                    <div className={"correo-container"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6 footer-info-icon">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/>
                        </svg>
                        <a href={"https://mail.google.com/mail/?view=cm&fs=1&to=servicioalcliente@snintegral.com"}
                           target={"_blank"} rel={"noopener noreferrer"}>
                            servicioalcliente@snintegral.com
                        </a>
                    </div>
                    <div className="correo-container">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6 footer-info-icon">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/>
                        </svg>

                        <a href={"https://mail.google.com/mail/?view=cm&fs=1&to=pedidos@snintegral.com"}
                           target={"_blank"} rel={"noopener noreferrer"}>
                            pedidos@snintegral.com
                        </a>
                    </div>
                    <div className={"footer-up-phone-div"}>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6 footer-info-icon">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"/>
                        </svg>
                        <a href="tel:+523521174644">
                            352 117 4644
                        </a>
                    </div>
                    <button className={"close-info-button"} onClick={() => setShowInfo(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" className="w-6 h-6 close-menu-btn">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContactUsInfo;